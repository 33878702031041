import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">Designtokens er vårt felles designspråk satt i system - bindeleddet mellom
alle flater.</PageHeader>
    <p>{`Ved bruk av pakken `}<em parentName="p">{`@entur/tokens`}</em>{` så har man tilgang til alle gjenbrukbare verdier som kan brukes for å designe sider og komponenter for å følge Entur sin visuelle identitet.
Disse kan aksesseres enten ved CSS, SASS, LESS eller Javscript.`}</p>
    <h2 {...{
      "id": "hva-er-tokens"
    }}>{`Hva er tokens?`}</h2>
    <p>{`Tokens i et designsystem er nøkkelverdier som representerer farger, typografi, spacing, skriftstørrelser osv. De er grunnleggende byggeklosser som brukes til å definere og vedlikeholde design på tvers av produkter.`}</p>
    <p>{`Å ha et sett med tokens gir flere fordeler. For det første muliggjør det konsistens, ved å sørge for at de samme designelementene brukes konsekvent overalt i et produkt. Dette skaper en helhetlig opplevelse for brukerne. Videre forenkler tokens vedlikeholdet, da endringer bare trenger å gjøres på ett sted, og de blir automatisk oppdatert over hele systemet. Dette sparer tid og reduserer risikoen for feil.`}</p>
    <p>{`Kort sagt, tokens er en nødvendig del av et designsystem for å oppnå konsistent design og effektivt vedlikehold av designelementer på tvers av et produkt eller en plattform.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      